a {
    color: #3df06a ;
}
.footer {
    background-color: #000000fa;
    margin-bottom: 0;
    padding-bottom: 0;
    overflow: hidden;
}
.section_padding{
    padding: 4rem 4rem;
}
.sb_footer {
    display: flex;
    flex-direction: column;
}
.sb_footer-links {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}
.sb_footer-links-div {
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #ffffff;
}
.sb_footer-links-div h4 {
    font-family: (--chakra-fonts-heading) !important;
    font-weight: var(--chakra-fontWeights-bold) !important;
}
.sb_footer-section_padding {
    padding-bottom: 0;
}
.socialmedia {
    display: flex;
    flex-direction: row;
}
.socialmedia img {
    width: 75%;
    height: auto;
    webkit-border-radius: 25px;
    moz-border-radius: 25px;
    border-radius: 25px;
    background: #3df06a;
}
.sb_footer-links-div p{
    font-size: 15px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
}
.sb_footer-below {
    display: flex;
    flex-direction: row;
}
.sb_footer-below-links p{
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color: #ffffff;
    font-weight: 600;
}
hr {
    background-color: #121212 !important;    
    width: 100%;
    height: 2px;
    border: none;
}
.socialmedia .a {
    color: rgb(175, 175, 179); 
    text-decoration: none;
    
}
.sb_footer-copyright {
    font-size: 13px;
    line-height: 15px;
    color: #ffffff;
    font-weight: 600;
}

/*SMALLER DISPLAYS*/
@media screen and (max-width: 850px) {
    .sb_footer-heading h1{
        font-size: 44px;
        line-height: 50px;
    }
}
@media screen and (max-width: 550px) {
    .sb_footer-heading h1{
        font-size: 34px;
        line-height: 42px;
    }
    .sb_footer-links div{
        margin: 1rem 0;
    }
    .sb_footer-btn p{
        font-size: 14px;
        line-height: 20px;

    }
    .sb_footer-below {
        flex-direction: column;
        justify-items: left;
    }
}
@media screen and (max-width: 400px) {
    .sb_footer-heading h1{
        font-size: 27px;
        line-height: 38px;
    }
}