.App {
  background-color: #000000;
  color: white;
  font-family: "Lucida Console", Monaco, monospace;
  display: flex;
  z-index: 10;
}

.logo {
  height: 4rem;
}

.content {
  width: 100%;
  padding: 20px;
  z-index: 1;
}

.hackers {
  color: greenyellow;
}

.header {
  font-family: "Lucida Console", Monaco, monospace !important;
}

.asdf {
  font-family: "Lucida Console", Monaco, monospace !important;
  font-size: 20px;
  color: greenyellow;
}

.shell {
  font-family: "Lucida Console", Monaco, monospace !important;
}

.learn {
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 30px;
}

.header {
  display: inline;
}
.nav-item {
  border: 1px solid yellowgreen;
  padding: 10px;
  border-radius: 10px;
  width: 23%;
  text-align: center;
  z-index: 15;
}

.nav-item:hover {
  background-color: yellowgreen;
  color: black;
}

.button {
  color: black !important;
  background-color: rgb(174, 232, 59) !important;
}

.button:hover {
  background-color: rgb(122, 163, 40) !important;
}

.butt {
  color: white !important;
  background-color: rgba(174, 232, 59, 0) !important;
  border: 1px solid rgb(174, 232, 59) !important;
  z-index: 10;
}

.butt:hover {
  background-color: rgb(174, 232, 59) !important;
  color: black !important;
}

.glitch {
  color: rgb(177, 255, 60);
  animation: glitch 30s 30s infinite;
}

.glitch::before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: -5px 0 rgb(255, 51, 0);
  background: black;
  overflow: hidden;
  top: 0;
  animation: noise-1 3s linear infinite alternate-reverse,
    glitch 5s 5.05s infinite;
}

.glitch::after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -5px 0 rgb(35, 128, 249);
  background: black;
  overflow: hidden;
  top: 0;
  animation: noise-2 3s linear infinite alternate-reverse, glitch 5s 5s infinite;
}

@keyframes glitch {
  z-index: 1;
  1% {
    transform: rotateX(10deg) skewX(90deg);
  }
  2% {
    transform: rotateX(0deg) skewX(0deg);
  }
}

@keyframes noise-1 {
  $steps: 30;
  @for $i from 1 through $steps {
    #{percentage($i*(1/$steps))} {
      $top: random(100);
      $bottom: random(101 - $top);
      clip-path: inset(#{$top}px 0 #{$bottom}px 0);
    }
  }
}

@keyframes noise-2 {
  $steps: 30;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      $top: random(100);
      $bottom: random(101 - $top);
      clip-path: inset(#{$top}px 0 #{$bottom}px 0);
    }
  }
}

.scanlines {
  overflow: hidden;
  mix-blend-mode: difference;
  z-index: 0;
}

.scanlines::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background: repeating-linear-gradient(
    to bottom,
    transparent 0%,
    rgba(255, 255, 255, 0.05) 0.5%,
    transparent 1%
  );
}

.glow {
  @extend .glitch;
  text-shadow: 0 0 1000px rgb(223, 191, 191);
  color: transparent;
  position: absolute;
  top: 0;
}

.column-icon {
  align-self: center !important;
  margin-bottom: 10px;
}

.box {
  border: 5px solid yellowgreen;
  z-index: 1;
  position: relative;
}
.box-h1 {
  text-align: center;
  vertical-align: middle;
  position: relative;
}
.box:before {
  position: absolute;
  top: -10px;
  left: 10%;
  content: "";
  display: block;
  height: 10px;
  width: 80%;
  background: rgb(0, 0, 0);
}
.box:after {
  position: absolute;
  bottom: -10px;
  left: 10%;
  content: "";
  display: block;
  height: 10px;
  width: 80%;
  background: rgb(0, 0, 0);
}

.avatar {
  height: 50px;
}

.mobile-heading {
  display: none;
}
