.popoverStyle {
    z-index: 99999 !important;
}

a.fc-daygrid-day-number {
    color: #f7fcf8 ;
}

/* Calendar General Styles */
.fc {
    background-color: #000000 !important;
    color: #3df06a !important;
}

/* Calendar Header */
.fc-toolbar-title {
    color: #f7fcf8 ;
    border: #000000 !important;
}

.fc-button {
    color: #000000 !important;
    background-color: #3df06a !important;
}

/* Event Styles */
.fc-h-event .fc-event-title-container,
.fc-direction-ltr .fc-daygrid-event.fc-event-end, 
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    color: #000000 !important;
    background-color: #3df06a !important;
}

/* List View Styles */
.fc-list-event-time,
.fc-list-event-title,
.fc-list-event-graphic,
.fc-list-table td,
.fc-list-day th {
    color: #f7fcf8 !important;
    background-color: #000000 !important;
}

/* Day Cells */
.fc-day,
.fc-day-today,
.fc-list-day-cushion,
.fc-popover {
    background-color: #000000 !important;
}

/* Event Dot */
.fc-daygrid-event-dot {
    border: calc(var(--fc-daygrid-event-dot-width)/2) solid #000000;
}
/* Event Dot in List View */
.fc-list-event-dot {
    border-color: #3df06a !important;
}

/* Event Dot in Day/Week/Month View */ 
.fc-daygrid-event-dot {
    border-color: #3df06a !important;
}
