.dot {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(54, 248, 54);
  opacity: 0;
  z-index: -10;
}

.dot-fade {
  animation: fade-in-out 2s ease-in-out;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
